<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onEditProForma" buttonText="" title="Modifier la facture ProForma">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']"/>
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadProForma" buttonText="" title="Télécharger le PDF" :disabled="!this.params.data.pdfGenerated">
            <template v-slot:iconBefore>
                PF
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListProFormaActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditProForma() {
                this.params.context.componentParent.editProForma(this.params.data);
            },
            onDownloadProForma() {
                this.params.context.componentParent.downloadProFormaInPdf(this.params.data.orderDocumentPath);
            }
        }
    }
</script>