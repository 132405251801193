<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    :deep(#articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2) {
        flex: 1;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer une facture Pro Forma" @click="displayCreateProFormaModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute="orderDocument/list"
        :apiParams="filter"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => editProForma(event))"
    >
    </Aggrid>
    <OrderDocumentCreateModal
        mode="proForma"
        orderDocumentRelatedType="quotation"
        title="CRÉER UNE FACTURE PRO FORMA"
        subtitleDocumentFrom="Basé sur le devis"
        :displayModal="displayModalCreateProForma"
        @onOrderDocumentCreated="onProFormaCreated"
        @onClose="() => { displayModalCreateProForma = false }"
    ></OrderDocumentCreateModal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentProFormaId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onProFormaUpdated"
    ></OrderDocumentModal>
</template>

<script>
    import CustomTopbar from '../../components/Topbar/CustomTopbar';
    import BaseButton from '../../components/Base/BaseButton';
    import BaseInput from '../../components/Base/BaseInput'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListProFormasActionButtonsRenderer from './ListProFormasActionButtonsRenderer.vue'
    import {formatPrice} from "../../utils/formatter";
    import config from "../../config";
    import OrderDocumentModal  from '../OrderDocument/OrderDocumentModal';
    import OrderDocumentCreateModal from '../OrderDocument/OrderDocumentCreateModal';
    import axios from 'axios';

    export default {
        name: 'ProFormas',
        components: {
            CustomTopbar,
            BaseButton,
            BaseInput,
            ListProFormasActionButtonsRenderer,
            Aggrid,
            OrderDocumentModal,
            OrderDocumentCreateModal
        },
        data() {
            return {
                columnDefs: [
                    {field: 'orderDocumentNumber', headerName: `Numéro`, flex: 1, lockPosition: true},
                    {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber'},
                    {field: 'billingName', headerName: `Client`, flex: 1, lockPosition: true},
                    {field: 'label', headerName: `Libellé`, flex: 1, lockPosition: true, wrapText: true},
                    {
                        field: 'amount',
                        headerName : `Montant`,
                        flex: 1,
                        lockPosition: true,
                        valueFormatterFunctionName: 'AmountValueFormatterFunction',
                        filter: 'agNumberColumnFilter'
                    },
                    {headerName: '', width: 220, cellRenderer: 'ListProFormasActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListProFormasActionButtonsRenderer: ListProFormasActionButtonsRenderer
                },
                valueFormatterFunctions: new Map([
                    [
                        'AmountValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.amount);
                        }
                    ]
                ]),
                context: null,
                filter: {
                    mode: 'proForma',
                    deleted: false
                },
                formatPrice: formatPrice,
                displayModalCreateProForma: false,
                displayOrderDocumentModal: false,
                orderDocuments: null,
                currentProFormaId: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        mounted() {
            if (this.$route.query?.action === 'displayOrderDocument') {
                this.editProForma({
                    id: parseInt(this.$route.query.orderDocumentId),
                    affairId: parseInt(this.$route.query.affairId)
                });
            }
        },
        methods: {
            downloadProFormaInPdf(proFormaPath) {
                if (proFormaPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${proFormaPath}`, '_blank').focus();
            },
            displayCreateProFormaModal() {
                this.displayModalCreateProForma = true;
            },
            onProFormaCreated(orderDocuments) {
                this.displayModalCreateProForma = false;
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onProFormaUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editProForma(proForma) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=proForma&${proForma.affairId ? `affairId=${proForma.affairId}` : `orderDocumentId=${proForma.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentProFormaId = proForma.id;
                    this.onProFormaCreated(result.data);
                })
                .catch(() => {});
            }
        }
    }
</script>